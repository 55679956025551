* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  overflow: hidden;
}

.container {
  max-width: 100%;
  padding-left: 124px !important;
  padding-right: 124px !important;
}

a {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 2.014vw;
  text-decoration-line: underline;
  color: inherit;
}

a:hover {
  color: #e6e8ec;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
  overscroll-behavior: none;
  scroll-behavior: initial !important;
  min-height: 100%;
  background-color: #ffffff;
}

body {
  font-family: "Roboto Mono", monospace;
  overflow-x: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  color: #e6e8ec;
  /* background-image: url("https://ik.imagekit.io/future/assets/tr:f-auto/noise.svg"), linear-gradient(128deg, #000, #141416); */
}

/* --------------- Navbar --------------- */

#header {
  position: relative;
}
.nav-head {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 22px 0;
  z-index: 1911;
}
.navbar-brand img {
  width: 72%;
}
.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-menu li {
  padding: 0 13px;
}
.nav-menu li a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-decoration: none;
}
.navbar-brand {
  width: 25%;
}
.nav {
  width: 75%;
  display: flex;
  justify-content: flex-end;
}

/* nav {
    position: absolute !important;
    width: 100%;
    z-index: 99;
}

.navbar {
    width: auto !important;
}
.navbar-brand img {
    width: 50%;
}

.navbar-nav .nav-item a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.01em;
    padding: 14px !important;
}
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    justify-content: space-evenly;
} */
/* --------------- Navbar End --------------- */

/* --------------- Home Page --------------- */

.home-hero {
  position: relative;
  background: linear-gradient(180deg, #5386d3 0%, rgba(56, 103, 173, 0.54) 100%)
    #78e49b;
  /* background-image: url('./../assets/img/jc-noise.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hhero-img {
  /* position: relative; */
  display: flex;
  justify-content: flex-end;
}
.hhero-img img {
  position: absolute;
  width: auto;
  /* height: 98vh; */
  height: 100vh;
  z-index: 98;
  right: 14vw;
  bottom: 0vw;
}

/* .marquee {
    position: relative;
} */
.marquee1 img {
  position: absolute;
  top: 19vw;
  bottom: 0;
  left: -75px;
  /* width: 100%; */
  max-width: 110%;
  transform: rotate(-14deg);
}

.marquee1 {
  position: relative;
  /* width: 100%; */
  height: 45rem;
  pointer-events: none;
  /* overflow: hidden; */
  transform: rotate(-14deg);
  z-index: 11;
}

.marquee__inner-wrap {
  height: 100%;
  width: 100%;
}

.marquee span {
  text-align: center;
  color: lightgray;
  font-weight: 400;
  white-space: nowrap;
  font-size: max(2vw, 2.4rem);
  line-height: 1.2;
  font-weight: 700;
  padding: 1vh 1vw 0;
  text-transform: uppercase;
}

.marquee__img {
  background-image: url("./../assets/img/mq-scrolling-img-hh.png");
  width: max(0rem, 102vw);
  height: 23vh;
  margin: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.marquee__inner {
  height: 100%;
  width: fit-content;
  align-items: center;
  display: flex;
  position: relative;
  animation: marquee1 32s linear infinite;
  will-change: transform;
  margin: 0 0 0 -400px;
}

@keyframes marquee1 {
  to {
    transform: translateX(-50%);
  }
}

.marquee {
  /* position: relative; */
  width: 100vw;
  max-width: 100%;
  height: 100px;
  overflow: hidden;
  z-index: 109;
}

.marquee .content {
  color: #e5fe53;
  font-size: 32px;
  font-weight: 500;
  font-style: italic;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.01em;
}

.marquee-dot {
  margin-top: -22px;
}

.track {
  position: absolute;
  bottom: 0;
  margin: 30px 0;
  z-index: 100;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* .line-images {
    position: relative;
    z-index: 109;
} */
.line-images .limg1 {
  position: absolute;
  top: 10vw;
  left: 0;
  pointer-events: none;
}
.line-images .limg2 {
  position: absolute;
  top: 9vw;
  right: 4vw;
  pointer-events: none;
}
.line-images .limg3 {
  position: absolute;
  bottom: 5vw;
  right: 12vw;
  z-index: 99;
  pointer-events: none;
}
.line-images .limg4 {
  position: absolute;
  bottom: -12vw;
  left: 0;
  z-index: 109;
  pointer-events: none;
}

/* --- About Section --- */

.about-home {
  position: relative;
  background-color: #ffc883;
  padding: 140px 0;
}

.about-home-cont {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.about-home-cont h2 {
  position: relative;
  color: #e2ff2f;
  font-size: 9.028vw;
  font-weight: 500;
  line-height: 9.306vw;
  margin-bottom: 0;
  z-index: 9;
  overflow: hidden;
}

.about-home-cont .haimg1 {
  width: 100%;
  margin: 34px 34px 0 0;
}
.about-home .haimg2 {
  position: absolute;
  left: -155px;
  bottom: 35px;
  /* width: 100%; */
  animation: rotat 5s infinite;
}

@keyframes rotat {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(50deg);
  }
  50% {
    transform: rotate(-50deg);
  }
  75% {
    transform: rotate(50deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.about-home .haimg3 {
  position: absolute;
  top: -60px;
  right: -30px;
  /* width: 100%; */
}
.about-home .haimg4 {
  position: absolute;
  top: -115px;
  left: 5px;
  /* width: 100%; */
}
.about-home-contxt {
  position: relative;
}
.about-home-cont0 {
  position: relative;
  display: flex;
  margin: 34px 0 0 0;
}
.about-home-cont0 .haimgarrow {
  margin: 0 50px 0 62px;
}
.about-home-cont0 p {
  color: #23262f;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  padding: 0 12px 0 0;
}

.h-about-anchor {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 160px;
}
.h-about-anchor a {
  color: #6201ff;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-decoration: none;
}
.h-about-anchor .h-about-line {
  position: relative;
  background-color: #6201ff;
  height: 2px;
  width: 62px;
  margin: 0 10px 0 0;
  /* border: 1px solid #000; */
  /* width: 20px; */
}

/* --- About Section End --- */
/* --- BO Section --- */

.bo-home {
  background: #855cf4;
  position: relative;
  padding: 80px 0;
}

.bo-home-cont-h2 {
  position: relative;
  overflow: hidden;
  height: 9.861vw;
}
.bo-home-cont h2 {
  position: relative;
  color: transparent;
  font-size: 9.583vw;
  font-weight: 500;
  line-height: 9.861vw;
  text-transform: uppercase;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #d9d9d9;
  text-shadow: 10px -10px 0px #d9d9d9, 10px -10px 0px #d9d9d9;
  z-index: 9;
}
.bo-home-cont p {
  color: #f4f5f6;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.h-bo-img1 {
  position: absolute;
  top: -30px;
  right: 14%;
  z-index: 1;
}
.bo-home-anchor {
  position: relative;
  overflow: hidden;
}
.bo-home-line {
  border-top: 1px solid #fff;
  margin: 42px 0 0 0;
  padding: 74px 0 0 0;
}
.bo-home-anchor a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.bo-home-anchor a p {
  color: #ffffff;
  font-size: 38px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: 0.04em;
  margin-left: 42px;
  margin-bottom: 0;
}

.btn-svg svg {
  display: block;
}
.btn-svg svg path {
  display: block;
  transition: 5s all;
}
.btn-svg:hover svg path {
  fill: #ffffff;
}
/* --- BO Section End --- */

/* --- Feofe Section --- */

.feofe-home {
  position: relative;
  overflow: hidden;
}
.feofe-home img {
  width: 100%;
  height: 100%;
}

.feofe-home-cont {
  position: relative;
  background-color: #78e49b;
  padding: 130px 12px 120px 40px !important;
  overflow: hidden;
}
.feofe-home-cont h4 {
  color: #0089cf;
  font-size: 5.278vw;
  font-weight: 500;
  line-height: 5.417vw;
  /* padding: 0 0 30px 0; */
  overflow: hidden;
}
.feofe-home-cont p {
  color: #23262f;
  font-weight: 500;
  font-size: 26px;
  line-height: 46px;
  letter-spacing: 0.01em;
}
.feofe-home-cont a {
  position: relative;
  text-decoration: none;
  overflow: hidden;
}
.feofe-home-cont a p {
  color: #0089cf;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  margin-top: 130px;
  margin-bottom: 0;
  overflow: hidden;
}
.feofe-home-cont .feofe-line {
  background: #23262f;
  width: 235px;
  height: 1px;
  border-bottom: 1px solid #0089cf;
}

.cl-slider {
  background-color: #bae7ff;
  padding: 60px 0;
}
.cl-slide .slick-list {
  border-top: 1px solid #848484;
  border-bottom: 1px solid #848484;
  padding: 24px 0;
}
.home-client-logo img {
  margin-top: 15px;
  max-width: 100%;
}

.cl-slider .slick-slide .home-client-logo {
  width: auto !important;
}
/* .cl-slider .slick-slide .home-client-logo img {
    width: auto !important;
} */
.cl-slider .slick-slide {
  text-align: center;
}

/* --- Feofe Section End --- */

/* --------------- Home Page End --------------- */
/* --------------- Business Opportunities --------------- */

.bo-hero {
  position: relative;
  background: #8c77f1;
  padding: 204px 0 40px 0;
  color: #ffffff;
  /* background-image: url('../assets/img/bohero-bg1.png');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom; */
}

.bo-hero .elements {
  position: relative;
  display: flex;
  align-items: center;
  /* background-image: url('../assets/img/bohero-bg.svg');
    background-repeat: no-repeat;
    background-size: 85%;
    background-position-x: center;
    background-position-y: center; */
}

.bo-hero .elements h4 {
  font-weight: 700;
  font-size: 2.222vw;
  line-height: 2.917vw;
}

.bo-lott1 {
  position: absolute;
  left: 18vw;
  top: -11px;
  width: 4.5vw;
}
.bo-lott2 {
  position: absolute;
  left: 18vw;
  bottom: 11px;
  width: 4.5vw;
}

.bo-hero .line {
  position: relative;
  overflow: hidden;
  height: 8.5vw;
  margin: 0;
}
.bo-hero .line h1 {
  position: absolute;
  font-weight: 700;
  font-size: 6.25vw;
  line-height: 8.5vw;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffe36e;
}

.h1before {
  position: relative;
  z-index: 1;
}

.h1before::before {
  background: #6646ff;
  border-radius: 10px;
  transform: rotate(-1.1deg);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 85%;
  left: 0;
  top: 10px;
  z-index: -1;
  transition: background 0.5s ease-in-out;
}

.bo-hero-content-2 {
  margin-top: 20px;
}

.bo-hero-content-2 img {
  width: 100%;
}

.bo-hero-content-2 h3 {
  margin-top: 100px;
  font-weight: 500;
  font-size: 1.667vw;
  line-height: 2.5vw;
  color: #f4f5f6;
}

.bo-hero-imgstc img {
  position: absolute;
  left: -14%;
  bottom: -8%;
  animation: rotat2 8s infinite;
}

@keyframes rotat2 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(50deg);
  }
  50% {
    transform: rotate(-50deg);
  }
  75% {
    transform: rotate(50deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.bo-s2 {
  background-color: #ffe36e;
  position: relative;
  overflow: hidden;
  color: #000;
  padding-bottom: 100px;
}

.bo-marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 13vh;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 20px 0;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #8c77f1;
  overflow: hidden;
  z-index: 109;
}

.bo-marquee .content {
  color: #8c77f1;
  font-weight: 400;
  font-size: 4.444vw;
  line-height: 4.583vw;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 0.01em;
  padding: 20px 0;
}

.marquee-dot {
  margin-top: -22px;
}
.bo-marquee span {
  display: inline-block;
  width: 1.181vw;
  height: 1.181vw;
  background: #8c77f1;
  border-radius: 100%;
  margin: 0 0 10px 0;
}

.bo-marquee .track {
  position: absolute;
  bottom: 0;
  margin: 0;
  z-index: 100;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.bo-s2-top {
  width: 100%;
  position: absolute;
  top: -1px;
  z-index: 10;
}

.bo-s2-text {
  position: relative;
  overflow: hidden;
}

.bo-s2-inner-text {
  margin-top: 50px;
  padding-right: 5vw;
  border-top: 1px solid #8c75f0;
  padding-top: 20px;
}

.bo-s2-inner-text .line {
  position: relative;
  overflow: hidden;
  height: 3.403vw;
}
.bo-s2-inner-text .line span {
  position: absolute;
  font-weight: 700;
  font-size: 2.222vw;
  line-height: 3.403vw;
  color: #7f6bea;
}

.bo-s2-inner-text p {
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 2vw;
}

.bo-s2-image-wrap {
  position: relative;
}

.bo-s2-img1 {
  width: 100%;
}

.bo-s2-img2 {
  position: absolute;
  bottom: -50px;
  right: -40px;
}

.bo-lott3 {
  position: absolute;
  right: -25%;
  bottom: -30%;
  width: 65%;
}

.bo-s3 {
  background: #8c77f1;
  position: relative;
  overflow: hidden;
  color: #fff;
  padding: 0 0 100px 0;
}

.bo-s3 h3 {
  text-align: center;
  font-weight: 400;
  font-size: 2.222vw;
  line-height: 3.333vw;
  padding: 120px 0 80px 0;
}
.bo-s3-content img {
  width: 100%;
}

.forms {
  position: relative;
  padding: 60px 0 0 0;
}
.bo-forms {
  position: relative;
  display: flex;
}
.bo-forms fieldset {
  margin: 0 0 24px 0;
}
.bo-forms input.fs-inpt,
textarea {
  display: block;
  width: 100%;
  height: 64px;
  background: #efefef;
  border: 2px solid #000000;
  box-shadow: 4px 6px 0px #000000;
  border-radius: 2px;
  padding: 20px 18px;
}
.bo-forms .bo-forms-spc {
  display: block;
  width: 50%;
  padding: 0 60px;
}
.cb-input {
  position: relative;
  display: flex;
  margin-bottom: 12px;
}
.cb-input input {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 4px;
}
input[type="checkbox"] {
  position: relative;
  border: 2px solid #ffffff;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
  line-height: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  appearance: none;
  /* opacity: .5; */
}

input[type="checkbox"]:hover {
  opacity: 1;
}

input[type="checkbox"]:checked {
  background-color: #000000;
  opacity: 1;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
input[type="checkbox" i]:focus-visible {
  outline-offset: 0 !important;
}
:focus-visible {
  outline: auto 0 !important;
}

.bo-forms label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
}
.bo-forms-spc h5 {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  margin-top: 8px;
}
.bo-forms-spc p,
.bo-forms-spc ul li,
.cb-input label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}
.bo-forms-spc p {
  margin-bottom: 24px;
}
.cb-input label {
  margin: 0 0 0 10px;
}
.bo-forms-spc ul {
  margin-bottom: 24px;
}

[type="file"] {
  height: 0;
  width: 0;
}
[type="file"] + label {
  color: #b1b5c4;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.389vw;
  font-weight: 400;
  line-height: 1.806vw;
  margin-right: 16px;
  margin-bottom: 12px;
  position: relative;
  text-decoration: underline;
}
.upr {
  color: #b1b5c4;
  font-size: 0.833vw;
  font-weight: 400;
  line-height: 1.111vw;
  text-decoration-line: underline;
  margin-bottom: 40px;
  padding: 0 0 20px 0;
  border: 0px;
  border-bottom: 0.5px solid #fff;
}
.input-brows {
  margin: 0;
  padding: 0;
}
.file-nm {
  color: #000000;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 32px;
  padding: 5px 32px 5px 0;
  cursor: pointer;
  text-decoration: underline;
}
.file-cho {
  margin: 4px 0 0 12px;
}

.submit-btn {
  background: #8a72ff;
  border: 2px solid #030205;
  box-shadow: 4px 6px 0px #000000;
  border-radius: 2px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  padding: 5px 32px;
  cursor: pointer;
}

/* --------------- Business Opportunities End --------------- */

/* --------------- About Us --------------- */

.about-hero {
  background: #6c7bff;
  padding: 180px 0 0 0;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.about-hero .line {
  position: relative;
  height: 8.2vw;
  overflow: hidden;
}
.about-hero .line span {
  position: absolute;
  font-weight: 700;
  font-size: 6.25vw;
  line-height: 8.2vw;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #e8e3db;
  margin: 0;
}

.about-hero .purpose {
  position: relative !important;
  z-index: 1;
  color: #6c7bff !important;
  /* transition: all 10s ease-in !important; */
  /* transform: translateX(100%) !important; */
}

.about-hero .purpose::before {
  background: #ffd646;
  border-radius: 10px;
  transform: rotate(-1.17deg);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 90%;
  left: -0%;
  top: 8px;
  z-index: -1;
  /* transition: all 10s ease-in-out; */
  /* transform: translateX(0%); */
}
.about-hero .values {
  position: absolute;
  z-index: 1;
}

/* .about-hero .values::before{
    content: url(../assets/img/about-value.png);
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 60%;
    z-index: -1;
} */

.about-hero-b-cont {
  position: relative;
  margin: 0 0 60px 0;
}

.about-lott {
  position: absolute;
  bottom: -3vw;
  left: 37vw;
  width: auto;
  /* margin: -50px 0 0 0; */
}
.about-lott2 {
  width: 11%;
  position: absolute;
  top: -50px;
  left: -60px;
}
.about-lott3 {
  position: absolute;
  top: -29%;
  left: -12%;
  width: 4%;
}
.about-lott4 {
  position: absolute;
  right: 25%;
  bottom: -25%;
  width: 4%;
}
.about-lott5 {
  position: absolute;
  right: 0;
  bottom: 6%;
  width: 20%;
}
.rr {
  width: 100%;
  height: 100vh;
  margin: 150px 0;
}
.about-hero-content {
  padding: 20px 0 100px 0;
  /* min-height: 1000px; */
}

.about-hero-content .image-side {
  position: relative;
}

.about-hero-content p,
.about-rt-cont p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
.about-hero-cont-pp {
  position: relative;
  margin: 0 150px 0 170px;
}
.about-hero-cont-pp .about-hero-pp {
  position: absolute;
  top: -50px;
  left: -60px;
}

.about-hero-top {
  position: relative;
}

.about-hero-bottom {
  position: relative;
  /* left: 30%;
    bottom: -70%; */
  margin: -116px 0px 0 65px !important;
  z-index: 1;
}

.about-hero-float {
  position: absolute;
  right: 0;
  bottom: 80px;
}

.about-hero-bottomd {
  position: absolute;
  bottom: -4px;
  width: 100%;
}

.about-rt {
  position: relative;
  background-color: #f3f3f3;
  padding: 130px 0;
  overflow: hidden;
}
.about-rt-cont {
  position: relative;
  margin: 0 100px 0 110px;
}
.about-rt-cont p {
  color: #000000;
}
.about-rt-img2 {
  position: absolute;
  top: 0;
  left: 0;
}
.about-rt-img3 {
  position: absolute;
  top: -40px;
  left: -80px;
}
.about-rt-img3-1 {
  position: absolute;
  right: 25%;
  bottom: -25%;
}
.about-rt-img4 {
  position: absolute;
  bottom: -110px;
  left: -130px;
  animation: rotat1 10s infinite;
}

@keyframes rotat1 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* --------------- About Us End --------------- */

/* ------------ Excess Inventory ------------ */

.ei-hero {
  position: relative;
  background: #477cad;
  padding: 204px 0 40px 0;
  color: #ffffff;
  overflow: hidden;
}
/* .ei-hero .elements1 {
    display: flex;
    align-items: center;
    background-image: url('../assets/img/ei-hero-img1.svg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
} */
.ei-hero .elements2 {
  position: relative;
}
.ei-hero .elements h4 {
  font-weight: 700;
  font-size: 2.222vw;
  line-height: 2.917vw;
}

.ei-hero .line {
  position: relative;
  overflow: hidden;
  height: 8.2vw;
}
.ei-hero .line h1 {
  position: absolute;
  display: inline-block;
  font-weight: 700;
  font-size: 6.25vw;
  line-height: 8.2vw;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #e8e3db;
}
.ei-hero h2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

.ei-hero .h1before {
  position: relative;
  z-index: 1;
}

.ei-hero .h1before::before {
  background: #2167a8;
  border-radius: 10px;
  transform: rotate(-1.17deg);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: auto;
  z-index: -1;
}

.bo-hero-content-1 {
  position: relative;
}
.ei-lott1 {
  position: absolute;
  right: 8vw;
  bottom: 6vw;
}
.ei-lott2 {
  position: absolute;
  left: 5vw;
  bottom: 3vw;
}

.ei-lott4 {
  width: 4vw;
}

.ei-img8 {
  position: absolute;
  right: -14%;
  bottom: -4%;
  animation: rotat3 10s infinite;
}

@keyframes rotat3 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ei-hero-content-2 {
  margin-top: 20px;
}

.ei-hero-content-2 img {
  width: 100%;
}

.ei-hero-content-2 h3 {
  margin-top: 100px;
  font-weight: 500;
  font-size: 1.667vw;
  line-height: 2.5vw;
  color: #f4f5f6;
  padding: 0 52px 0 0;
}

.ei-hero-img3-4 {
  position: relative;
  margin: 80px 0;
}
.ei-img3 {
  position: absolute;
  top: -145px;
  right: 50px;
  width: auto !important;
  z-index: 99;
}
.ei-img5 {
  width: 100%;
  position: absolute;
  top: -1px;
  z-index: 10;
}

.ei-s2 {
  background-color: #e8e3db;
  position: relative;
  overflow: hidden;
  color: #000;
  padding: 100px 0 160px 0;
  /* background-image: url('../assets/img/ei-hero-img8.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom; */
}

.ei-s2-cont h3 {
  color: #3f2511;
  font-size: 3.333vw;
  font-weight: 500;
  line-height: 4.028vw;
  text-transform: capitalize;
  margin-bottom: 24px;
}
.ei-s2-cont p {
  color: #3f2511;
  font-size: 1.389vw;
  font-weight: 400;
  line-height: 1.806vw;
  text-transform: capitalize;
}

.ei-s3 {
  background: #477cad;
  position: relative;
  overflow: hidden;
  color: #fff;
  padding: 0 0 100px 0;
}
.ei-s3 h3 {
  text-align: center;
  font-weight: 400;
  font-size: 2.222vw;
  line-height: 3.333vw;
  padding: 120px 0 80px 0;
}

.ei-s3 .submit-btn {
  background: #e8e3db;
  color: #000000;
}

/* ------------ Excess Inventory End ------------ */

/* ------------ Store Locator ------------ */

.st-hero {
  position: relative;
  padding: 180px 0 80px 0;
}
.st-tabs {
  position: relative;
  padding: 100px 0;
}
.st-tabs .nav {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 0;
  margin: 0 0 50px 0;
}
.st-tabs .nav-tabs .nav-link {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.34);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border: 2px solid #000000;
  box-shadow: 3px 5px 0px #000000;
  border-radius: 2px;
  margin: 0 16px 0 0;
  padding: 16px 40px 16px 14px;
}
.st-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #707fff;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border: 2px solid #000000;
  box-shadow: 3px 5px 0px #000000, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

.pune .pune-add {
  padding-bottom: 32px;
}
.tab-content h4 {
  color: #6f7eff;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
}
.tab-content p {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;
}
.tab-content a {
  display: block;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  margin-bottom: 12px;
}

.st-tabs .st-btn {
  background: #707fff;
  border: 2px solid #000000;
  box-shadow: 3px 5px 0px #000000;
  border-radius: 2px;
  display: inline-block;
  margin: 12px 0 0 0;
  padding: 14px 24px 14px 14px;
}
.st-tabs .st-btn p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}
.st-map {
  position: relative;
  margin: 50px 0 0 0;
}

/* ------------ Store Locator End ------------ */

/* --------------- Footer --------------- */

footer {
  background-color: #bae7ff;
  color: #000;
  padding: 40px 0;
  text-align: center;
}

footer h4 .fuoi-txt {
  color: #353945;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}
footer h4 a:hover {
  color: #000;
}

footer img {
  width: auto;
  margin-bottom: 40px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.footer-border {
  height: 1px;
  border-bottom: 0.5px solid #51a7d6;
  margin-top: 30px;
  margin-bottom: 22px;
}

.footer-menu ul {
  display: flex;
  justify-content: center;
  list-style: none;
}
.footer-menu li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #252525;
  padding: 8px 12px;
  text-decoration: none;
}

.footer-c {
  margin-top: 30px;
}

.footer-c h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #353945;
  margin-bottom: 14px;
}

.footer-c a {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.07em;
  color: #353945;
  text-decoration: none;
}

/* --------------- Footer End --------------- */

/* Popup */

.modal {
  z-index: 1912;
}

.modal-dialog,
.modal-dialog-scrollable {
  width: 100vw;
  height: 100vh !important;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  background-color: #0089cf;
  position: relative;
  display: flex;
  align-items: center;
}

.modal-content {
  border: 0px solid transparent;
  background-color: #0089cf;
}

.modal-header {
  background-color: #0089cf;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-close {
  color: #fff;
  background: transparent
    url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 10L10 30' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 10L30 30' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    center/40px auto no-repeat;
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  padding: 0.25em 0.25em;
  color: #fff;
  border-radius: 0;
  opacity: 1;
  position: absolute;
  z-index: 100000;
  right: 124px;
  top: 40px;
}

.modal-body {
  padding: 0;
  background-color: #0089cf;
}

.modal-body .container {
  min-height: 100vh;
}

.modal-buu {
  cursor: pointer;
}

.contact-popup {
  padding: 80px 0;
}

.contact-popup h2 {
  font-weight: 700;
  font-size: 85px;
  line-height: 100px;
  margin-bottom: 28px;
}
.contact-popup a {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
}

.contact-popup .bo-forms input.fs-inpt,
textarea {
  height: 54px;
  padding: 20px 18px;
}

.contact-popup .bo-forms {
  display: block;
}

.contact-popup .bo-forms-spc {
  width: 100%;
  padding: 0;
}

.contact-popup textarea {
  width: 100%;
  height: 100px;
}

.contact-popup .submit-btn {
  background: #0972a8;
  border: 2px solid #030205;
  box-shadow: 4px 6px 0px #000000;
  border-radius: 2px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  padding: 5px 32px;
  cursor: pointer;
  width: 100%;
}

.mrg-top {
  margin-top: -50px !important;
}

sup {
  color: #ea6b6b;
}

.ei-hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -20px;
  right: -30px;
  background-color: #fac825;
}

.tabs-container nav {
  margin: 0 auto;
  background: #fff;
}

.tabs-container nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tabs-container nav ul li {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.34);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border: 2px solid #000000;
  box-shadow: 3px 5px 0px #000000;
  border-radius: 2px;
  margin: 0 16px 0 0;
  padding: 16px 40px 16px 14px;
}

.tabs-container nav ul li:hover {
  color: #3e4b58;
}

.tabs-container nav ul li.active {
  background: #707fff;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  border: 2px solid #000000;
  box-shadow: 3px 5px 0px #000000, inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
}

/* the nitty gritty */
.tabs-container {
  position: relative;
  height: 4em;
  padding: 0 0 100px 0;
}

.tabs-container nav {
  position: absolute;
  width: 100%;
  z-index: 9;
}

.tabs-container nav ul {
  display: flex;
  flex-direction: column;
}

.tabs-container nav ul li {
  order: 1;
}

.tabs-container nav ul li.active {
  order: 0;
}

.tabs-container nav ul li.active:after {
  width: 1px;
  height: 1px;
  border: 5px solid transparent;
  border-top: 5px solid #555;
  content: "";
  position: absolute;
  right: 1.5em;
  top: 1.4em;
  z-index: 9999;
}

.tabs-container nav ul li:not(.active) {
  position: absolute;
  top: -999em;
}

.tabs-container nav ul.expanded li.active:after {
  border-top-color: transparent;
  border-bottom-color: #555;
  top: 1em;
}

.tabs-container nav ul.expanded li:not(.active) {
  position: relative;
  top: auto;
}

@media all and (min-width: 52em) {
  nav {
    background: transparent;
  }

  /* .tabs-container nav ul li.active {
      box-shadow: inset 0 -3px 0 #5098B3;
    } */

  .tabs-container nav ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    border: none;
    padding: 0 0 6px 0;
    cursor: pointer;
  }

  /* .tabs-container nav ul li {
      display: inline-block;
      margin: 0 1rem 0 1rem;
      padding: 0 0 0.2rem 0;
      border: none;
    } */

  .tabs-container nav ul li:not(.active) {
    position: relative;
    top: auto;
  }

  .tabs-container nav ul li.active {
    order: 1;
  }

  .tabs-container nav ul li.active:after {
    display: none;
  }
}
.tab-content {
  display: none;
}

.tab-content.current {
  display: inherit;
}

.errors {
  color: red !important;
}
.cb-input-file span,
label span {
  color: #fff !important;
}

.fs-inpt {
  margin-bottom: 10px;
}

/* Form Submitted Popup */

.swal-modal {
  background-color: #ffe36e !important;
  width: 500px !important;
  padding: 50px;
}
/* .swal-icon {
    display: none !important;
  } */
.swal-icon--success:after,
.swal-icon--success:before,
.swal-icon--success__hide-corners,
.swal-icon--success__ring {
  display: none !important;
}
.swal-icon--success {
  border-color: #353945 !important;
}
.swal-icon--success__line {
  background-color: #353945 !important;
}
.swal-title {
  color: #353945 !important;
  font-family: "Roboto Mono", monospace !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 42px !important;
}
.swal-text {
  display: none !important;
}
.swal-footer {
  text-align: center !important;
}
.swal-button {
  background-color: #8a72ff !important;
  border-radius: 2px !important;
  font-family: "Roboto Mono", monospace !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 6px 52px !important;
  border: 2px solid #030205 !important;
  box-shadow: 4px 6px 0px #000000 !important;
}

@media (max-width: 767px) {
  .swal-modal {
    width: 380px !important;
    padding: 40px;
  }
  .swal-title {
    font-size: 26px !important;
    line-height: 34px !important;
  }
  .swal-button {
    font-size: 14px !important;
    padding: 6px 42px !important;
  }
}

#picture,
.form-control:disabled {
  background-color: transparent !important;
}

/* Form Submitted Popup End */
